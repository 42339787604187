const SET_CHARACTERS = "SET_CHARACTERS";
const SET_FAVOURITES = "SET_FAVOURITES";
const SET_PAGES_COUNT = "SET_PAGES_COUNT";
const SET_CHARACTER = "SET_CHARACTER";
const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

export default {
  SET_CHARACTERS,
  SET_FAVOURITES,
  SET_PAGES_COUNT,
  SET_CHARACTER,
  SET_NOTIFICATIONS,
};
