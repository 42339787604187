const FETCH_CHARACTERS = "FETCH_CHARACTERS";
const FETCH_FAVOURITES = "FETCH_FAVOURITES";
const ADD_TO_FAVOURITES = "ADD_TO_FAVOURITES";
const REMOVE_FROM_FAVOURITES = "REMOVE_FROM_FAVOURITES";
const FETCH_CHARACTER = "FETCH_CHARACTER";
const ADD_NOTIFICATION = "ADD_NOTIFICATION";
const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

export default {
  FETCH_CHARACTERS,
  FETCH_FAVOURITES,
  ADD_TO_FAVOURITES,
  REMOVE_FROM_FAVOURITES,
  FETCH_CHARACTER,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
};
